import BtnDefault from "../btn/btn-default";
import React, { Component } from "react";
import SectionCard from "./section-card";

class SectionGrid extends Component {

    constructor(props)
    {
        super(props);

        this.state = {
            loadMore: false,
        };

        this.handleLoadMoreClick = this.handleLoadMoreClick.bind(this);
    }

    handleLoadMoreClick()
    {
        this.setState(prevState => ({
            loadMore: !prevState.loadMore
        }));
    }

    render()
    {
        const { className, items, min } = this.props;
        const { loadMore } = this.state;

        let minDisplay = 3;

        if (min) {
            minDisplay = min;
        }

        if ( !items || !items.length ) {
            return "";
        }

        const itemsContent = items.map((item, index) => {
            let itemClassName = "mb-60 md:mb-0";

            if ( !loadMore && (index + 1) > (this.props.maxMobile ?? minDisplay)) {
                itemClassName = itemClassName + " hidden sm:block";
            }

            return (
                <SectionCard
                    className={ itemClassName + ( item.className ? " " + item.className : "") }
                    key={ index }
                    href={ item.href }
                    image={ item.image }
                    title={ item.title }
                    subtext={ item.subtext }
                />
            );
        });

        let loadMoreBtnContent;

        if ( !loadMore ) {
            loadMoreBtnContent = (
                <BtnDefault className="sm:hidden cursor-pointer" type="outline" onClick={ this.handleLoadMoreClick }>
                    Load More
                </BtnDefault>
            );
        }

        return (
            <div className={ "" + (className ? " " + className : "") }>
                <div className="md:grid md:grid-cols-2 xl:grid-cols-3 gap-20">
                    { itemsContent }
                </div>
                { loadMoreBtnContent }
            </div>
        );
    }
}

export default SectionGrid;