import AboutUs from '../components/blocks/about-us';
import AsxWidgets from '../components/blocks/asx-widgets';
import Brands from '../components/blocks/brands';
import OurValues from '../components/blocks/our-values';
import HeroSolvar from '../components/blocks/hero-solvar';
import MainLayout from '../components/layouts/main-layout';
import WebsiteSections from '../components/blocks/website-sections';
import AfsLogo from '../public/img/logos/afs_01.svg';
import GcfLogo from '../public/img/logos/gcf_01.svg';
import Money3Logo from '../public/img/logos/money3_02.svg';
import ScrollDownArrow from '../components/scroll/scroll-down-arrow';
import Head from 'next/head';
import NewBrand from '../components/blocks/new-brand';

export default function HomePage() {
    return (
        <MainLayout>
            <Head>
                <title>Solvar - Specialist Finance Providers</title>
            </Head>
            <HeroSolvar />
            <div id="scrollDownAnchor"></div>
            <AsxWidgets />
            <NewBrand />
            <AboutUs />
            <WebsiteSections />
            <OurValues />
            <Brands
                items={[
                    {
                        src: AfsLogo,
                        href: "https://www.afs.com.au/",
                        alt: "Automotive Financial Services"
                    },
                    {
                        src: GcfLogo,
                        href: "https://www.gocar.co.nz/",
                        alt: "Go Car Finance"
                    },
                    {
                        src: Money3Logo,
                        href: "https://www.money3.com.au/",
                        alt: "Money3"
                    }
                ]}
            />
            <ScrollDownArrow anchor="scrollDownAnchor" />
        </MainLayout>
    );
}