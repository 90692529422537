import Image from 'next/image';
import Link from 'next/link';
import BtnDefault from '../btn/btn-default';

export default function SectionCard(props) {
    const { className, title, subtext, href, image } = props;

    let readMoreContent;

    if ( href ) {
        readMoreContent = (
            <BtnDefault
                className="block absolute right-30 bottom-30 group-hover:underline"
                type="outline"
                size="small"
            >Read More</BtnDefault>
        );
    }

    const content = (
        <>
            <div className="relative">
                <Image
                    className="group-hover:opacity-80"
                    src={ image }
                    alt={ title }
                    layout="responsive"
                    objectFit="cover"
                    quality="100"
                />
            </div>
            <div className="p-30">
                <p className="text-h4 text-bb text-decoration-none pb-30 capitalize">
                    <strong>{ title }</strong>
                </p>
                <p className="text-black pb-100">{ subtext }</p>
                { readMoreContent }
            </div>
        </>
    );

    if ( href ) {
        return (
            <Link href={ href } className={ "relative block text-left no-underline group shadow" + (className ? " " + className : "") }>
                { content }
            </Link>
        );
    }

    return (
        <div className={ "relative text-left shadow" + (className ? " " + className : "") }>
            { content }
        </div>
    );
}