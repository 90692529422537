import Image from "next/image";
import Link from 'next/link';
import React from "react";
import VideoImage from '../../public/img/ui/brand_video_preview_04.jpg';

export default function NewBrand(props)
{
    return (
        <section className="bg-grey">
            <div className="container py-60 lg:py-100 md:grid md:grid-cols-12 md:gap-40 xl:gap-60 md:items-center">
                <div className="sm:px-30 md:col-span-5 text-center md:text-left">
                    <h1 className="mb-20 xl:mb-30 md:text-h2 lg:text-h1 xl:text-[3.0rem] xl:leading-[3.6rem]">Introducing Our New Company Brand</h1>
                    <p className="mb-40 md:mb-0">Money3 Corporation Limited has been re-named to Solvar Limited. For more information about this name change, please watch our video.</p>
                </div>
                <div className="col-span-7">
                    <Link href="https://www.youtube.com/watch?v=ijqoI6yPIf8" className="relative block bg-bb rounded sm:rounded-lg sm:shadow text-center overflow-hidden group" target="_blank" passHref>
                        <Image
                            src={ VideoImage }
                            alt="Solvar Video"
                            layout="responsive"
                        />
                        <span className="bg-white absolute block left-0 right-0 top-0 bottom-0 opacity-0 group-hover:opacity-10 transition"></span>
                    </Link>
                </div>
            </div>
        </section>
    );
}