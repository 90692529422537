import AfsLogo from '../../public/img/logos/afs_white_01.svg';
import CareIcon from '../../public/img/ui/care_respect_01.svg';
import GcfLogo from '../../public/img/logos/gcf_white_01.svg';
import GrowingIcon from '../../public/img/ui/growing_together_01.svg';
import HeartIcon from '../../public/img/ui/customers_heart_01.svg';
import HeroImage from '../../public/img/hero/our_values_02.jpg';
import Image from 'next/image';
import IntegrityIcon from '../../public/img/ui/delivery_integrity_01.svg';
import Mask from '../../public/img/ui/our_values_mask_02.svg';
import Money3Logo from '../../public/img/logos/money3_white_01.svg';
import SolvarLogo from '../../public/img/logos/solvar_white_01.svg';

export default function OurValues()
{
    return (
        <section className="relative bg-bb">
            <div className="absolute right-0 top-0 h-full w-full z-hero-bg">
                <Image src={ HeroImage } alt="Our Values" layout="fill" objectFit="cover" objectPosition="right center" />
            </div>
            <div className="xl:hidden bg-bb absolute left-0 right-0 top-0 bottom-0 z-hero-bg opacity-80"/>
            <div className="hidden xl:block absolute right-0 top-0 h-full w-full z-hero-bg opacity-80">
                <Image src={ Mask } alt="mask" layout="fill" objectFit="cover" objectPosition="right center" />
            </div>
            <div className="relative sm:container py-60 z-hero-fg">
                <div className="text-center mb-30">
                    <div className="inline-block relative w-[130px] sm:w-[268px] mr-10 sm:mr-30">
                        <Image src={ SolvarLogo } alt="Solvar" layout="responsive" />
                    </div>
                    <h1 className="inline-block text-white sm:text-[88px]">Values</h1>
                </div>
                <div className="sm:flex lg:px-100 mb-30 max-w-[960px] mx-auto">
                    <div className="relative h-[45px] sm:h-[35px] md:h-[45px] sm:grow mb-30">
                        <Image src={ AfsLogo } alt="Automotive Financial Services" layout="fill" />
                    </div>
                    <div className="relative h-[45px] sm:h-[35px] md:h-[45px] sm:grow mb-30">
                        <Image src={ GcfLogo } alt="Go Car Finance" layout="fill" />
                    </div>
                    <div className="relative h-[45px] sm:h-[35px] md:h-[45px] sm:grow mb-30">
                        <Image src={ Money3Logo } alt="Money3" layout="fill" />
                    </div>
                </div>
                <div className="md:grid md:grid-cols-2 lg:flex lg:justify-between lg:h-[680px] xl:h-[660px] lg:px-[180px] max-w-[1160px]">
                    <div className="relative lg:w-px">
                        <div className="bg-white rounded rounded-full w-[360px] h-[360px] text-center py-20 px-[40px] mb-30 mx-auto lg:absolute lg:top-0 lg:left-half lg:ml-[-180px]">
                            <div className="py-20">
                                <div className="relative w-full h-[90px]">
                                    <Image src={ CareIcon } alt="Solvar" layout="fill" />
                                </div>
                            </div>
                            <p className="font-bold mb-10">Care and respect</p>
                            <p>We don't judge or make assumptions. We treat people as individuals and with utmost respect. We listen, we empathise, we help.</p>
                        </div>
                    </div>
                    <div className="relative lg:w-px">
                        <div className="bg-white rounded rounded-full w-[360px] h-[360px] text-center py-20 px-[40px] mb-30 mx-auto lg:absolute lg:bottom-0 lg:left-half lg:ml-[-180px]">
                            <div className="py-20">
                                <div className="relative w-full h-[90px]">
                                    <Image src={ HeartIcon } alt="Solvar" layout="fill" />
                                </div>
                            </div>
                            <p className="font-bold mb-10">Customers are at the heart of everything we do</p>
                            <p>We deliver empowering innovative products designed around the customer's needs.</p>
                        </div>
                    </div>
                    <div className="relative lg:w-px">
                        <div className="bg-white rounded rounded-full w-[360px] h-[360px] text-center py-20 px-[40px] mb-30 mx-auto lg:absolute lg:top-0 lg:left-half lg:ml-[-180px]">
                            <div className="py-20">
                                <div className="relative w-full h-[90px]">
                                    <Image src={ IntegrityIcon } alt="Solvar" layout="fill" />
                                </div>
                            </div>
                            <p className="font-bold mb-10">Delivering with integrity</p>
                            <p>We always do our best. We are responsible for our actions and not afraid to learn from our mistakes.</p>
                        </div>
                    </div>
                    <div className="relative lg:w-px">
                        <div className="bg-white rounded rounded-full w-[360px] h-[360px] text-center py-20 px-[40px] mb-30 mx-auto lg:absolute lg:bottom-0 lg:left-half lg:ml-[-180px]">
                            <div className="py-20">
                                <div className="relative w-full h-[90px]">
                                    <Image src={ GrowingIcon } alt="Solvar" layout="fill" />
                                </div>
                            </div>
                            <p className="font-bold mb-10">Growing together</p>
                            <p>We grow great people who are always up for the challenge and look out for one another. We love to have fun together and celebrate our wins.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}