import { YourIR } from 'yourir-next';

export default function AsxSharePrice(props)
{
    const { className } = props;

    return (
        <YourIR>
            <div className={ "sol-asx-shareprice" + (className ? " " + className : "") }>
                <p data-yourir="currentTime format='[As of] Do MMMM YYYY'"></p>
                <div className="flex font-bold">
                    <div className="flex items-stretch flex-col">
                        <span
                            className="sol-asx-shareprice-price text-cc"
                            data-yourir="price minDecimals=2 maxDecimals=2"
                        ></span>
                        <div>
                            <span>Change:</span> <span className="text-right" data-yourir="change"></span>
                        </div>
                    </div>
                    <span className="text-cc" data-yourir="symbol"></span>
                </div>
            </div>
        </YourIR>
    );
}