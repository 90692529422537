import AsxAnnouncements from '../asx/asx-announcements';
import AsxSharePrice from '../asx/asx-share-price';
import Link from 'next/link';

export default function AsxWidgets(props)
{
    const { className } = props;

    return (
        <div className={ "bg-white md:py-40" + (className ? " " + className : "") }>
            <div className="md:container md:grid md:grid-cols-3">
                <div className="relative container flex flex-col px-30 py-30 md:py-0">
                    <h2 className="pb-20">Announcements</h2>
                    <AsxAnnouncements />
                    <div className="grow flex justify-end items-end">
                        <Link href="/investors/company-announcements" className="text-cc no-underline hover:underline group" passHref>
                            Company Announcements
                            <span className="inline-block pl-5">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.8 11.59" height="10">
                                    <path d="M1,1,5.8,5.8,1,10.59" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"2px"}}/>
                                </svg>
                            </span>
                        </Link>
                    </div>
                    <div className="hidden md:block absolute top-20 bottom-20 right-0 w-2 bg-grey-2"></div>
                </div>
                <div className="bg-grey md:bg-transparent">
                    <div className="container px-30 py-30 md:py-0">
                        <h2 className="grow pb-20">Share price</h2>
                        <AsxSharePrice />
                    </div>
                </div>
                <div className="relative container flex flex-col px-30 py-30 md:py-0">
                    <h2 className="pb-20">Corporate governance</h2>
                    <p className="pb-10">We are committed to the highest standards of corporate governance, transparency and establishing accountability.</p>
                    <div className="grow flex justify-end items-end">
                        <Link href="/about-us/corporate-governance" className="text-cc no-underline hover:underline group" passHref>
                            Corporate Governance
                            <span className="inline-block pl-5">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.8 11.59" height="10">
                                    <path d="M1,1,5.8,5.8,1,10.59" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"2px"}}/>
                                </svg>
                            </span>
                        </Link>
                    </div>
                    <div className="hidden md:block absolute top-20 bottom-20 left-0 w-2 bg-grey-2"></div>
                </div>
            </div>
        </div>
    );
}