import Caption from "../text/caption"

export default function AboutUs(props)
{
    const { className } = props;

    return (
        <section className={ "bg-white" + (className ? " " + className : "") }>
            <div className="container py-60 text-center">
                <Caption>About us</Caption>
                <h2 className="text-h1 pb-20">About our company</h2>
                <p>Solvar Limited is a leading provider of automotive and personal finance across Australia and New Zealand. The Group is made up of three business units, Money3, Automotive Financial Services (AFS) and Go Car Finance (GCF). We specialise in providing flexible secured and unsecured vehicle and personal loans to customers often excluded by traditional lenders.</p>
            </div>
        </section>
    );
}