import Image from 'next/image';
import Link from 'next/link';

export default function Brands(props)
{
    const { className, items } = props;

    if ( !items || !items.length ) {
        return "";
    }

    const itemsContent = items.map((item, index) => {
        return (
            <div key={ index } className="relative mb-20 sm:mb-0 last:mb-0 h-60">
                <Link href={ item.href } className="relative block w-full h-full" target="__blank" passHref>
                    <Image src={ item.src } alt={ item.alt } layout="fill" />
                </Link>
            </div>
        );
    });

    return (
        <div className={ "bg-white text-center" + (className ? " " + className : "") }>
            <div className="container py-60">
                <h2 className="text-h1 pb-20">Our Brands</h2>
                <div className="sm:grid sm:grid-cols-3 gap-40">
                    { itemsContent }
                </div>
            </div>
        </div>
    );
}