import { YourIR } from 'yourir-next';

export default function AsxAnnouncements(props)
{
    const { showCount } = props

    return (
        <YourIR>
            <div data-yourir={ "announcements pageSize=" + (showCount ?? 2) }>
                <a className="flex border-b-2 border-grey-2 last:border-none cursor-pointer group no-underline py-10">
                    <p className="whitespace-nowrap pr-20" data-yourir="$cur.date format='DD/MM/YYYY'"></p>
                    <p className="whitespace-nowrap group-hover:underline overflow-hidden text-black text-ellipsis" data-yourir="$cur.headingText"></p>
                </a>
            </div>
        </YourIR>
    );
}