import React from "react";
import BtnDefault from "../btn/btn-default";
import Image from "next/image";
import HeroImage from '../../public/img/hero/solvar_hero_04.jpg';

export default function HeroSolvar(props)
{
    const imgClasses = ""

    return (
        <section className="relative bg-bb mt-[102px] lg:mt-[127px] overflow-hidden">
            <div className="relative min-h-[40vh] sm:min-h-[auto] sm:absolute sm:left-[-40px] lg:left-[-20px] xl:left-0 sm:right-0 sm:top-0 sm:bottom-0 bg-bb z-hero-bg">
                <Image
                    className={ imgClasses }
                    src={ HeroImage }
                    alt="solvar"
                    layout="fill"
                    // height="" // Statically loaded image height not needed.
                    // width="" // Statically loaded image width not needed.
                    objectPosition="top 30% left 0"
                    objectFit="cover"
                    quality="100"
                />
            </div>
            <div className="relative container sm:flex z-hero-fg">
                <div className="hidden sm:block h-1 grow"></div>
                <div className="py-40 md:py-80 xl:py-100">
                    <h1 className="text-hero-sm md:text-hero-lg lg:text-hero-xl xl:text-hero-xxl pt-[14px] md:pt-[32px] text-white pb-30">
                        Finance<br />
                        Solved
                    </h1>
                    <BtnDefault href="/about-us/company-overview" size="small" color="white" type="outline">Company Overview</BtnDefault>
                </div>
            </div>
        </section>
    );
}